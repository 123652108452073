/**
 * Utility functions for any storefront wizard region
 * TO-DO: refactor StorefrontWizardRegion and possibly CentralizedWizardRegion to make use of this utils file
 */

import { ClientWizardStateStore } from "src/stores/wizard/ClientWizardStateStore";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";
import { WizardModuleDataOptions } from "src/components/flexComponents/WizardPWARegions/StorefrontWizardRegionMeso/typings";
import { WizardBackgroundImageOverrideProps } from "src/components/flexComponents/WizardPWARegions/typings";
import { ExtendedTemplateComponent } from "src/stores/ExperienceTemplateStore/typings";
import { WizardComponentTemplateComponentConfig } from "./typings";

/**
 *
 * @param flexModules Array of flex modules coming from the flex manager
 * Returns all modules corresponding to a wizard LOB form
 */

export const getWizardLOBFlexComponents = (flexModules: ExtendedTemplateComponent[]): ExtendedTemplateComponent[] => {
  return flexModules.filter((module: ExtendedTemplateComponent) => {
    return module && module.metadata.id && module.metadata.id.includes("wizard");
  });
};

/**
 *
 * @param templateComponents Array of templateComponent
 * Returns all templateComponents corresponding to a wizard LOB form
 */

export const getWizardLOBTemplateComponents = (
  templateComponents: ExtendedTemplateComponent[]
): ExtendedTemplateComponent[] => {
  return templateComponents.filter((module: ExtendedTemplateComponent) => {
    const key = module?.metadata?.id;
    return key && key.toString().includes("wizard") && !key.toString().includes("biased");
  });
};

/**
 *
 * @param templateComponents Array of templateComponent
 * Returns all templateComponent that do not correspond to a wizard LOB form
 */

export const getNonWizardLOBTemplateComponents = (
  templateComponents: ExtendedTemplateComponent[]
): ExtendedTemplateComponent[] => {
  return templateComponents.filter((module: ExtendedTemplateComponent) => {
    return !module?.metadata?.id?.includes("wizard");
  });
};

/**
 *
 * @param templateComponents Array of templateComponent
 * Returns all templateComponents that do not correspond to a wizard LOB form and they're above the wizard
 * Does not categorize templateComponents further
 */
export const getAboveNonWizardTemplateComponents = (
  templateComponents: ExtendedTemplateComponent[]
): ExtendedTemplateComponent[] => {
  const firstIndexWizardChildren = templateComponents.findIndex((child) => {
    const key = child?.metadata.id;
    return key && key.toString().includes("wizard");
  });

  //splice to get the elements before the first wizard element
  return templateComponents.splice(0, firstIndexWizardChildren).filter((child) => {
    const key = child?.metadata.id;
    return key && !key.toString().includes("wizard");
  });
};

/**
 *
 * @param templateComponents Array of templateComponent
 * Returns all templateComponents that do not correspond to a wizard LOB form and they're below the wizard
 * Does not categorize templateComponents further
 */
export const getBelowNonWizardTemplateComponents = (
  templateComponents: ExtendedTemplateComponent[]
): ExtendedTemplateComponent[] => {
  const firstIndexWizardChildren = templateComponents.findIndex((child) => {
    const key = child?.metadata.id;
    return key && key.toString().includes("wizard");
  });

  return templateComponents.filter((child, childIndex) => {
    const key = child?.metadata.id;
    return key && !key.toString().includes("wizard") && childIndex > firstIndexWizardChildren;
  });
};

/**
 *
 * @param flexModules Array of flex modules coming from the flex manager
 * Returns all modules corresponding to a wizard LOB form
 */
export const getBiasedWizardLOBTemplateComponent = (
  flexModules: ExtendedTemplateComponent[]
): ExtendedTemplateComponent[] => {
  return flexModules.filter((module: ExtendedTemplateComponent) => {
    return module?.metadata?.id?.includes("biased");
  });
};

export const getWizardImageOverride = (
  templateComponents: ExtendedTemplateComponent[],
  context: ExtendedContextStore
): WizardBackgroundImageOverrideProps | undefined => {
  const {
    locale,
    site: { id: siteid },
  } = context;
  let backgroundImage;
  let backgroundImageLocale;
  templateComponents.forEach((child) => {
    const key = child?.metadata.id;
    if (key && key.includes("background-image-override")) {
      const options = child.config as WizardComponentTemplateComponentConfig;
      const childSiteid = options?.siteid;
      const childLocale = options?.locale;
      const matchSiteId = childSiteid === siteid;
      const backgroundObject = {
        src: options?.backgroundUrl,
        color: options?.backgroundColor,
      };
      if (matchSiteId && !childLocale) {
        backgroundImage = backgroundObject;
      } else if (matchSiteId && childLocale === locale) {
        backgroundImageLocale = backgroundObject;
      }
    }
  });
  return backgroundImageLocale ?? backgroundImage;
};

/**
 * Based on a list of templateComponents, will filter and return
 * the ones that are part of Packages as standalone LOBs
 * i.e. Hotels, Flights and Cars
 */
export const getStandaloneLOBModulesInPackages = (children: ExtendedTemplateComponent[]) => {
  const standaloneLobsInPackages = ["wizard-hotel-pwa-v2", "wizard-flight-pwa", "wizard-car-pwa"];

  return children.filter((child) => child.type && standaloneLobsInPackages.includes(child.type));
};

/**
 * This function overrides the configs for the biased wizard
 * Which takes flexcomponent and wizardState as input.
 * Used in StoreFrontWizardRegionMeso
 */
export const getMesoWizardModuleData = (options: WizardModuleDataOptions, wizardState: ClientWizardStateStore) => {
  const globalConfig = wizardState.globalWizardState.config;
  globalConfig.halfWizard = options.halfWizard || false;
  globalConfig.selectedProduct = options.selectedProduct;
  globalConfig.defaultOrigin = options.biasingOriginAttributeValue;

  globalConfig.defaultMultipleDestination =
    options.biasingDestinationAttributeMap !== undefined
      ? options.biasingDestinationAttributeMap["" as keyof object]
      : undefined;
  globalConfig.defaultDestination = options.biasingDestinationAttributeValue;
  globalConfig.defaultStopover = options.biasingStopoverAttributeValue;
  globalConfig.defaultMultipleStopover = options.biasingStopoverAttributeMap
    ? options.biasingStopoverAttributeMap["" as keyof object]
    : undefined;

  globalConfig.defaultMultipleOrigin =
    options.biasingOriginAttributeMap !== undefined ? options.biasingOriginAttributeMap["" as keyof object] : undefined;
  globalConfig.destinationDropdownTypeahead = options.destDropdownTypeahead;
  globalConfig.calendarFilterCarrierCode = options.calendarFilterCarrierCode;
  globalConfig.displayBiasedWizard = !options.empty;
  globalConfig.userLocation = options.airportName;
  globalConfig.destiantionType = options.destinationBiased;
  globalConfig.originType = options.originBiased;

  const hotelConfig = wizardState.hotelWizardState.config;
  wizardState.hotelWizardState.overrideConfig(() => {
    hotelConfig.hotelID = options.biasingAttributeHotelValue;
    hotelConfig.hotelName = options.biasingAttributeHotelName;
    hotelConfig.hotelChainID = options.biasingAttributeHotelChainID;
    hotelConfig.multiHotelIDs =
      (options.biasingAttributeMultiHotelValue && options.biasingAttributeMultiHotelValue.split(";")) || undefined;
    hotelConfig.isBiasedWizardEmpty = options.empty;
    hotelConfig.paymentTypePreset = options.paymentTypePreset;
  });

  const carConfig = wizardState.carWizardState.config;
  wizardState.carWizardState.overrideConfig(() => {
    carConfig.carType = options.biasingAttributeCarType;
    carConfig.carVendorCode = options.biasingAttributeCarVendorCode;
    carConfig.carVendorName = options.biasingAttributeCarVendorName;
    carConfig.carDiscountTypeCode = options.carDiscountTypeCode;
    carConfig.carDiscountTypeName = options.carDiscountTypeName;
    carConfig.carDiscountCode = options.carDiscountCode;
    carConfig.showCarDiscount = options.showCarDiscount;
    carConfig.isBiasedWizardEmpty = options.empty;
  });

  const cruiseConfig = wizardState.cruiseWizardState.config;
  wizardState.cruiseWizardState.overrideConfig(() => {
    cruiseConfig.cruiseLine = options.biasingAttributeCruiseLine;
  });

  const flightConfig = wizardState.flightWizardState.config;
  wizardState.flightWizardState.overrideConfig(() => {
    flightConfig.airlineOptions = options.airlineOptions;
    flightConfig.cabinClass = options.biasedCabinClass;
    if (!!globalConfig.defaultStopover || !!globalConfig.defaultMultipleStopover) {
      flightConfig.multiCityFlight.maxNumberOfAdditionalLegs = 1;
      flightConfig.multiCityFlight.minNumberOfLegs = 1;

      const getOriginValue = () => {
        if (globalConfig.defaultStopover) {
          return {
            stopoverName: globalConfig.defaultStopover,
            stopoverRegionId: "",
          };
        }
        if (globalConfig.defaultMultipleStopover && globalConfig.defaultMultipleStopover.length > 0) {
          const value = globalConfig.defaultMultipleStopover[0].split(":");

          return {
            stopoverName: value[0],
            stopoverRegionId: value[1],
          };
        }

        return {
          stopoverName: "",
          stopoverRegionId: "",
        };
      };

      const { stopoverName, stopoverRegionId } = getOriginValue();

      wizardState.flightWizardState.listOfAdditionalLegs.push({
        location: {
          origin: {
            value: stopoverName,
            metaData: {
              regionId: stopoverRegionId,
              ttla: "",
              latLong: "",
              countryCode: "",
            },
          },
          destination: {
            value: "",
            metaData: {
              regionId: "",
              ttla: "",
              latLong: "",
              countryCode: "",
            },
          },
        },
        date: wizardState.globalWizardState.getDateFromConfig(wizardState.flightWizardState.config.date),
      });
    }
  });

  const packageConfig = wizardState.packageWizardState.config;
  wizardState.packageWizardState.overrideConfig(() => {
    packageConfig.multiHotelIDs =
      (options.biasingAttributeMultiHotelValue && options.biasingAttributeMultiHotelValue.split(";")) || undefined;
    packageConfig.disableToggleOnPackage = options.disableToggleOnPackage;
  });
};
