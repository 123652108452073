import * as React from "react";
import { LobQueryRouterProps, ActiveLobSwitchProps } from "./typings";
import { QueryRoute } from "bernie-core";
import { observer } from "mobx-react";
import { ItemKeyHelper } from "src/components/utility/ItemKeyHelper";
import { wizardLobQueryParamKey } from "../constants";
import { withStores } from "stores";
import { ExtendedTemplateComponent } from "src/stores/ExperienceTemplateStore/typings";

/**
 * The source of truth to determinate whether a lob form will be rendered or not
 * Is fully managed by the WizardState, this router is the interface bettewn
 * The query param in the url and the wizardState
 */

// Define all the possible routes base on the flex template
export const LobQueryRouter = withStores(
  "wizardState",
  "flexModuleModelStore"
)(
  observer((props: LobQueryRouterProps) => {
    const { lobsTemplateComponent, wizardState, flexModuleModelStore } = props;
    const keyHelper = new ItemKeyHelper("lobQueryRouter");
    const [isModuleModelStoreInitialized, setModuleModelStoreInitialized] = React.useState(false);

    /*
     * Temporary workaround to initialize wizard modules on server side rendering
     * from the flexModuleModelStore in the modelMap Map property.
     * We can remove this when the flex template renderer is refactored 100% in blossom.
     * Details of this implementation on this confluence page:
     * https://confluence.expedia.biz/display/POS/Flex+template+renderer+react+refactor+under+Wizard.
     */
    if (!isModuleModelStoreInitialized && flexModuleModelStore) {
      flexModuleModelStore.getModel("wizard-flight-pwa-1");
      flexModuleModelStore.getModel("wizard-hotel-pwa-v2-1");
      flexModuleModelStore.getModel("wizard-cruise-pwa-1");
      flexModuleModelStore.getModel("wizard-package-pwa-1");
      flexModuleModelStore.getModel("wizard-thirdPartyPackage-pwa-1");
      flexModuleModelStore.getModel("wizard-car-pwa-1");
      flexModuleModelStore.getModel("wizard-gt-pwa-1");
      flexModuleModelStore.getModel("wizard-vr-pwa-1");
      flexModuleModelStore.getModel("wizard-lx-pwa-1");
      flexModuleModelStore.getModel("wizard-hotel-pwa-1");
      flexModuleModelStore.getModel("wizard-externalLinkTab-pwa-1");
      setModuleModelStoreInitialized(true);
    }

    if (!wizardState) {
      return null;
    }

    if (lobsTemplateComponent) {
      if (lobsTemplateComponent.length === 1 && lobsTemplateComponent[0].type) {
        // For single lob set it as an active instead of relying on the QueryRoute
        wizardState.setActiveLob(lobsTemplateComponent[0].type);

        return null;
      }

      if (lobsTemplateComponent.length === 0) {
        return null;
      }

      return (
        <>
          {lobsTemplateComponent.map((templateComponent: ExtendedTemplateComponent) => (
            <QueryRoute key={keyHelper.next()} query={{ [wizardLobQueryParamKey]: templateComponent.type ?? "" }}>
              <ActiveLobSwitch lobName={templateComponent.type ?? ""} />
            </QueryRoute>
          ))}
        </>
      );
    }

    return null;
  })
);

// Component called by QueryRoute which its only purpose is to set the active lob
const ActiveLobSwitch = withStores("wizardState")(
  observer((props: ActiveLobSwitchProps) => {
    const { wizardState, lobName } = props;
    if (wizardState) {
      wizardState.setActiveLob(lobName);
    }

    return null;
  })
);
