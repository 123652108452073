import { FlexComponentNameToWizardStateConfig } from "components/shared/StorefrontWizard/FlexComponentNameToWizardStateConfig";
import { ClientWizardStateStore } from "stores/wizard/ClientWizardStateStore";
import { LOB_KEYS } from "../../constants";

/**
 * getGTLabelTokenFromState:
 * returns the gtLabelToken from car's config
 * @param wizardState contains the current lob configuration from overrides and default config
 */
export const getGTLabelTokenFromState = (wizardState: ClientWizardStateStore) =>
  getLobConfigFromState(LOB_KEYS.CAR, wizardState)?.gtConfig?.navigation?.tabTitleToken;

/**
 * getLobConfigFromState:
 * returns the configuration for the provided lob
 * @param lobName contains the current lob name
 * @param wizardState contains the current lob configuration from overrides and default config
 */
export const getLobConfigFromState = (lobName: string, wizardState: ClientWizardStateStore) =>
  // @ts-ignore
  wizardState[FlexComponentNameToWizardStateConfig[lobName]]?.config;
