import { FlightType } from "src/stores/wizard/state/typings";
import {
  CarWizardState,
  CruiseWizardState,
  FlightWizardState,
  HotelWizardState,
  LOBState,
  LxWizardState,
  ThirdPartyPackageWizardState,
} from "stores/wizard/state";

export const getShoppingGlobalMicroMessage = (lobState: LOBState) => {
  const formatDate = (tripDate: Date) => {
    return tripDate?.toISOString().substring(0, 10);
  };

  let originCode: string | undefined = lobState.location.origin.metaData.ttla;
  let destinationCode = lobState.location.destination.metaData.ttla;
  const tripStartDate = formatDate(lobState.date.start);
  let tripEndDate: string | undefined = formatDate(lobState.date.end);

  const isHotelOrLx: boolean = lobState instanceof HotelWizardState || lobState instanceof LxWizardState;
  const isCruise: boolean = lobState instanceof CruiseWizardState;
  const isAllInclusivePackage = lobState instanceof ThirdPartyPackageWizardState;
  const isNotRoundTripFlight =
    lobState instanceof FlightWizardState && lobState.flightTripType !== FlightType.ROUND_TRIP;
  const isGroundTransportation: boolean =
    lobState instanceof CarWizardState &&
    lobState.groundTransportationLocationState?.location.origin.metaData.regionId !== "" &&
    lobState.groundTransportationLocationState?.location.origin.metaData.ttla !== "";

  if (isHotelOrLx) {
    originCode = undefined;
    destinationCode = lobState.location.destination.metaData.regionId;
  } else if (isCruise) {
    originCode = undefined;
    destinationCode = (lobState as CruiseWizardState).cruiseDestination;
  } else if (isNotRoundTripFlight) {
    tripEndDate = undefined;
  } else if (isGroundTransportation) {
    const carWizardState = lobState as CarWizardState;
    originCode = carWizardState.groundTransportationLocationState?.location.origin.metaData.ttla;
    destinationCode = carWizardState.groundTransportationLocationState?.location.origin.metaData.regionId;
  } else if (isAllInclusivePackage) {
    const allInclusiveWizardState = lobState as ThirdPartyPackageWizardState;
    originCode = allInclusiveWizardState.origin;
    destinationCode = allInclusiveWizardState.destination;
    tripEndDate = undefined;
  }

  const uisShoppingGlobalMicroMessage = [
    {
      schemaName: "shoppingGlobal",
      messageContent: {
        originCode,
        destinationCode,
        tripStartDate,
        tripEndDate,
      },
    },
  ];

  return uisShoppingGlobalMicroMessage;
};
