// The name of the wizard flex components is different than the the lob state from the wizard state
// This enum maps the name of the flex component with its wizardState to ease its access
export enum FlexComponentNameToWizardStateConfig {
  "wizard-hotel-pwa" = "hotelWizardState",
  "wizard-hotel-pwa-v2" = "hotelWizardState",
  "wizard-package-pwa" = "packageWizardState",
  "wizard-thirdPartyPackage-pwa" = "thirdPartyPackageWizardState",
  "wizard-car-pwa" = "carWizardState",
  "wizard-cruise-pwa" = "cruiseWizardState",
  "wizard-flight-pwa" = "flightWizardState",
  "wizard-lx-pwa" = "lxWizardState",
  "wizard-externalLinkTab-pwa" = "externalLinkTabWizardState",
  "wizard-vr-pwa" = "vrWizardState",
}
