import * as React from "react";
import { ClientWizardStateStore } from "src/stores/wizard/ClientWizardStateStore";
import { observer, inject } from "mobx-react";

interface WizardProps {
  wizardState?: ClientWizardStateStore;
}

// Component that extract the logic to determinate whether a form needs to be rendered base on the WizardState
export const TabbedWizard: React.FunctionComponent<WizardProps> = inject("wizardState")(
  observer(({ wizardState, children }) => {
    if (!wizardState) {
      return null;
    }

    return <>{children}</>;
  })
);
