import * as React from "react";
import { inject, observer } from "mobx-react";

import { classNames } from "@egds/react-core/utils";
import { UitkCard } from "@egds/react-core/cards";
import {
  MinMax,
  UitkLayoutConditionalGridTrack,
  UitkLayoutGrid,
  UitkLayoutGridItem,
} from "@egds/react-core/layout-grid";
import { UitkSpacing, UitkSpacingProps } from "@egds/react-core/spacing";

import { ClientWizardStateStore } from "stores/wizard/ClientWizardStateStore";
import { LobFormWithTabs } from "components/shared/StorefrontWizard/LobFormWithTabs/LobFormWithTabs";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { UitkTabsType } from "uitk-react-experimental-button-tabs";
import { ExtendedTemplateComponent } from "src/stores/ExperienceTemplateStore/typings";
import { BlossomComponent } from "src/components/flexFramework/domain/BlossomComponent";
import { RegionChildrenList } from "src/utils/RegionUtils";
import { useExperiment } from "@shared-ui/experiment-context";
import { useMediaQueries, defaultViewSizes } from "@shared-ui/viewport-context";

export interface TabbedWizardCardProps {
  context?: ExtendedContextStore;
  wizardState: ClientWizardStateStore;
  wizardFormChildren: ExtendedTemplateComponent[];
  wrappingClassNames?: string;
  wrappingSpacing?: UitkSpacingProps;
  tabGroupClassNames?: string;
  wizardFormClassNames?: string;
  wizardFormSpacing?: UitkSpacingProps;
  wizardRegionType: WizardRegionType;
  trackOnChangeTab?: (tabName: string) => void;
  hasBorder?: boolean;
  tabsType?: UitkTabsType;
  blossomComponent: BlossomComponent;
}

export enum WizardRegionType {
  BEX,
  COMET,
  MESO,
  TAAP,
}

const getPaddingBasedOnLobSize = (childrenSize: number) => {
  if (childrenSize === 1) return { block: "six" };

  if (childrenSize > 1) return { blockend: "none" };

  return {};
};

const getAlohaCardClassName = (
  wizardRegionType: WizardRegionType,
  hasBorder: boolean,
  wizardFormChildren: ExtendedTemplateComponent[]
) => {
  const wizardsWithElevation: WizardRegionType[] = [WizardRegionType.BEX, WizardRegionType.MESO, WizardRegionType.TAAP];
  return classNames(
    { elevation: !hasBorder && wizardsWithElevation.includes(wizardRegionType) },
    { multiLobCard: wizardRegionType === WizardRegionType.BEX && wizardFormChildren.length > 1 }
  );
};

const rowsLayout = (viewportSize: number) => {
  const firstRow = MinMax("18x", "1fr");
  if (viewportSize === defaultViewSizes.MEDIUM) {
    return [firstRow, MinMax("min-content", "5fr")];
  }
  return [firstRow, MinMax("min-content", "2fr")];
};

/**
 * Returns a tabbed wizard within a uitk-card
 * Padding/styling of elements within card are handed down by parent region
 * Assumes any non-wizard form children are handled by parent region
 */
export const TabbedWizardCard: React.FunctionComponent<TabbedWizardCardProps> = inject(
  "wizardState",
  "context"
)(
  observer(
    ({
      context,
      wizardState,
      wizardFormChildren,
      wrappingClassNames = "",
      wrappingSpacing,
      tabGroupClassNames = "",
      wizardFormClassNames = "",
      wizardFormSpacing,
      wizardRegionType,
      trackOnChangeTab,
      hasBorder = false,
      tabsType = UitkTabsType.NATURAL,
      blossomComponent,
    }: TabbedWizardCardProps) => {
      if (!wizardState) {
        return null;
      }
      const { halfWizard } = wizardState.globalWizardState.config;
      const mainGridColumns: UitkLayoutConditionalGridTrack = {
        large: halfWizard ? ["6fr", "6fr"] : ["12fr", "0fr"],
      };
      const isSingleLOB = wizardFormChildren?.length === 1;
      const lobPadding = getPaddingBasedOnLobSize(wizardFormChildren.length);
      const viewportSize = useMediaQueries({ viewSizes: defaultViewSizes });
      const { exposure } = useExperiment("Search_wizard_only_above_the_fold");
      const isVariantEnabled = exposure.bucket === 1;

      return (
        <UitkSpacing {...wrappingSpacing}>
          <UitkLayoutGrid
            columns={mainGridColumns}
            className={`wizardCard ${wrappingClassNames}`}
            rows={isVariantEnabled ? rowsLayout(viewportSize) : undefined}
          >
            <UitkLayoutGridItem rowEnd={isVariantEnabled ? "end" : ""}>
              <UitkSpacing padding={lobPadding as UitkSpacingProps["padding"]}>
                <UitkCard
                  border={hasBorder}
                  className={getAlohaCardClassName(wizardRegionType, hasBorder, wizardFormChildren)}
                >
                  <div>
                    {isSingleLOB ? (
                      <RegionChildrenList templateComponents={wizardFormChildren} blossomComponent={blossomComponent} />
                    ) : (
                      <LobFormWithTabs
                        context={context}
                        templateComponents={wizardFormChildren}
                        blossomComponent={blossomComponent}
                        wizardFormClassNames={wizardFormClassNames}
                        wizardFormSpacing={wizardFormSpacing}
                        tabGroupClassNames={tabGroupClassNames}
                        trackOnChangeTab={trackOnChangeTab}
                        tabsType={tabsType}
                      />
                    )}
                  </div>
                </UitkCard>
              </UitkSpacing>
            </UitkLayoutGridItem>
            <UitkLayoutGridItem />
          </UitkLayoutGrid>
        </UitkSpacing>
      );
    }
  )
);
