import { ExternalLinkTabConfig } from "stores/wizard/config";
import { FlexComponentNameToWizardStateConfig } from "components/shared/StorefrontWizard/FlexComponentNameToWizardStateConfig";
import { ClientWizardStateStore } from "stores/wizard/ClientWizardStateStore";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { LOB_KEYS } from "../../constants";
import {
  StorefrontWizardRegionBEXTemplateComponent,
  StorefrontWizardRegionBEXTemplateComponentConfig,
} from "src/components/flexComponents/WizardPWARegions/StorefrontWizardRegionBEX/typings";
import { ExtendedTemplateComponent } from "src/stores/ExperienceTemplateStore/typings";

/**
 * isExtLinkTab:
 * returns true if provided lobName string param equals LOB_KEYS.EXT_LINK
 * @param lobName contains the current lob name
 * @returns boolean
 */
export const isExtLinkTab = (lobName: string) => LOB_KEYS.EXT_LINK === lobName;

/**
 * getExternalLinkTabConfig:
 * returns the unlocalized configuration needed for the external link wizard tab by
 * checking for config from Flex Manager first and then from site id overrides and then for default config
 * @param lob contains the current lob configuration from Flex Manager
 * @param wizardState contains the current lob configuration from overrides and default config
 * @returns ExternalLinkTabConfig or null
 */
export const getExternalLinkTabConfig = (
  lob: ExtendedTemplateComponent | null,
  wizardState: ClientWizardStateStore | null
): ExternalLinkTabConfig | null => {
  if (!lob) {
    return null;
  }

  const lobName = lob.type || "";
  const config = lob.config as StorefrontWizardRegionBEXTemplateComponentConfig;

  if (!isExtLinkTab(lobName) || !wizardState) {
    return null;
  }

  const { tabURL, addLocaleToURL, iconKey, labelToken, rfrrId } = config;
  // @ts-ignore
  const localConfig: ExternalLinkTabConfig = wizardState[FlexComponentNameToWizardStateConfig[lobName]]?.config;
  const { tabIcon, tabTitleToken } = localConfig.navigation;

  return {
    tabURL: tabURL || localConfig?.tabURL,
    addLocaleToURL: addLocaleToURL !== undefined ? addLocaleToURL : localConfig?.addLocaleToURL,
    navigation: {
      tabIcon: iconKey || tabIcon,
      tabTitleToken: labelToken || tabTitleToken,
    },
    rfrrId,
  };
};

/**
 * getExternalLinkTabURL:
 * returns the final URL as described by the configuration
 * @param lobName contains the current lob name
 * @param context current context store that includes the current locale
 * @param config existing externalLinkTab configuration
 * @param lob current external link lob
 * @param wizardState contains the current lob configuration from overrides and default config
 * @returns string or null
 */
export const getExternalLinkTabURL = (
  lobName: string,
  context: ExtendedContextStore | undefined,
  config?: ExternalLinkTabConfig | null,
  lob: StorefrontWizardRegionBEXTemplateComponent | null = null,
  wizardState: ClientWizardStateStore | null = null
): string | null => {
  if (!isExtLinkTab(lobName) || (!config && (!wizardState || !lob))) {
    return null;
  }

  const configuration: ExternalLinkTabConfig | null = config || getExternalLinkTabConfig(lob, wizardState);

  if (configuration?.addLocaleToURL) {
    return `${configuration?.tabURL}&locale=${context?.locale}`;
  }
  return configuration?.tabURL || null;
};
